import { getTokenFromCookies } from 'utils';
import api from './api';
import { reactAppVersion, conf } from '../config/env';
export interface Headers {
    headers: {
        Accept: string;
        Authorization: string;
        'X-Front-Version': string;
    };
}

export const getHeaders = <T extends {}>(extra: T = null): Headers => {
    return {
        headers: {
            ...extra,
            Accept: 'application/vnd.bandc.v1+json',
            Authorization: `Token ${getTokenFromCookies()}`,
            'X-Front-Version': reactAppVersion,
        },
    };
};

export const logCall = (method: string, url: string, params: Record<string, any>) => {
    if (navigator.userAgent && navigator.userAgent.indexOf('jsdom') === -1) {
        // eslint-disable-next-line no-console
        console.log(
            `calling axiosI.${method.toLocaleLowerCase()} ${conf.api}/${url} with params:`,
            params,
            ' and headers: ',
            getHeaders(),
        );
    }
};

export default api;
