import { Env, InjectedEnv } from './config';

const envConfig: InjectedEnv = {
  overrideEnv: process.env.REACT_APP_ENV,
  GATracker: process.env.REACT_APP_GA_TRACKER,
  GMapAPIKey: process.env.REACT_APP_GMAP_API_KEY,
  fbc: {
    clientId: process.env.REACT_APP_FBC_CLIENT_ID,
  },
  appsignal: process.env.REACT_APP_APPSIGNAL,
};

const DEV: Omit<Env, keyof InjectedEnv> = {
  api: 'http://api.localdev.com:3000/community',
  apiRoot: 'http://api.localdev.com:3000',
  websocket: 'ws://api.localdev.com:3000/cable',
  loginRoot: `http://kolsquare.localdev.com:3004`,
  cookieToken: '_kolsquare_api_token_development',
  urls: {
    app: 'http://community.localdev.com:3002',
    kolsquare: `https://kolsquare.localdev.com:3004`,
    login: `http://kolsquare.localdev.com:3004/login`,
    admin: `http://admin.localdev.com:3003`,
  },
};

const STAGING: Omit<Env, keyof InjectedEnv> = {
  api: 'https://api-staging.kolsquare.com/community',
  apiRoot: 'https://api-staging.kolsquare.com',
  websocket: 'wss://api-staging.kolsquare.com/cable',
  cookieToken: '_kolsquare_api_token_staging',
  urls: {
    app: 'https://community-staging.kolsquare.com',
    kolsquare: `https://staging.kolsquare.com`,
    login: `https://staging.kolsquare.com/login`,
    admin: `https://admin-staging.kolsquare.com`,
  },
};

const LAB: Omit<Env, keyof InjectedEnv> = {
  api: 'https://api-lab.kolsquare.com/community',
  apiRoot: 'https://api-lab.kolsquare.com',
  websocket: 'wss://api-lab.kolsquare.com/cable',
  cookieToken: '_kolsquare_api_token_lab',
  urls: {
    app: 'https://community-lab.kolsquare.com',
    kolsquare: `https://lab.kolsquare.com`,
    login: `https://lab.kolsquare.com/login`,
    admin: `https://admin-lab.kolsquare.com`,
  },
};

const PROD: Omit<Env, keyof InjectedEnv> = {
  api: 'https://api.kolsquare.com/community',
  apiRoot: 'https://api.kolsquare.com',
  websocket: 'wss://api.kolsquare.com/cable',
  cookieToken: '_kolsquare_api_token',
  urls: {
    app: 'https://community.kolsquare.com',
    kolsquare: `https://www.kolsquare.com`,
    login: `https://www.kolsquare.com/login`,
    admin: `https://admin.kolsquare.com`,
  },
};

export const MOCK: Omit<Env, keyof InjectedEnv> = {
  ...DEV,
};

export const ENV_DEV = 'env---dev';
export const ENV_STAGING = 'env---staging';
export const ENV_LAB = 'env---lab';
export const ENV_PROD = 'env---prod';
export const ENV_MOCK = 'env---mock';

// REACT_APP_ENV=staging npm start
const overrideEnv = process.env.REACT_APP_ENV;

export const reactAppVersion = process.env.REACT_APP_VERSION;
export const isMock = process.env.REACT_APP_MOCKS === 'true';
export const isDev = process.env.NODE_ENV === 'development';
const location = window.location.hostname;

export const DEV_DEBUG = false;

const getEnv = () => {

  // If ENV is given, use it
  if(overrideEnv) {
    switch (overrideEnv) {
      case 'prod':
        return ENV_PROD;
      case 'staging':
        return ENV_STAGING;
      case 'lab':
        return ENV_LAB;
      default:
        throw new Error(`Incorrect ENV override: ${overrideEnv}`);
    }
  }

  if (isMock) return ENV_MOCK;
  if (location.indexOf('localhost') !== -1) return ENV_DEV;
  if (location.indexOf('localdev') !== -1) return ENV_DEV;
  if (location.indexOf('192.168.') !== -1) return ENV_DEV;
  if (location.indexOf('staging') !== -1) return ENV_STAGING;
  if (location.indexOf('lab') !== -1) return ENV_LAB;
  return ENV_PROD;
};

export const env = getEnv();

export const conf: Env = {
  ...envConfig,
  ...(env === ENV_DEV
    ? DEV
    : env === ENV_STAGING
    ? STAGING
    : env === ENV_LAB
    ? LAB
    : PROD),
};

export const mailToRecipe = 'community@brandandcelebrities.com';

export const KOL_MINIMUM_AGE = 13;
